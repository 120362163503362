<template>
  <div class="head_top">
    <img src="../../../../public/images/二级海报/无忧售后.jpg" width="100%" height="100%" lazy>

    <div class="size_box">
      <div class="title">售后政策</div>
      <h3>尊敬的客户：</h3>
      <p>您好！感谢您使用智驹产品，对于您在使用过程中造成的不便，很抱歉，为了更好、更高效为您服务，现公司制定规范返修流程，请知悉！</p>
      <p>一、建议先确认机器是否需要返厂处理，可以先联系客服（客服电话: 400-602- 9990 ；企业QQ :400-621-7868 )进行问题排除或者到代理店核实机器情况后再看是否需要返厂处理。</p>
      <p>二、如需返厂维修 【返厂注意事项】</p>
      <p>>>> 特别提醒：发返修机器时，请记录好您的返修机器机身编码和快递单号以备查询返修进度。</p>
      <p>>>>邮寄地址 
        深圳市石岩街道罗租社区黄蜂岭工业大道2号G栋1楼
        电话：0755 – 2673 6182-585
        收件人：维修部</p>
      <p>>>>其他说明</p>
      <p>1、随机附上一张纸上写明您机器的故障、详细地址、联系电话和姓名、邮箱，并注明返厂的物品明细。</p>
      <p>2、返厂机器除了电源外，其它配件(支架/螺丝/说明书/等)无须返厂，以减少维修时间。注电源最好一起返厂，以便查明故障原因。</p>
      <p>3、返厂物品请选择“送货上门”的快递服务，建议不要使用普包或者挂号信邮寄方式以防出现收件时间的延误。而导致无法及处理，且我司无到快递公司网点取件的服务。注明：拒收到付快递邮件，请知悉！ </p>
      <p>>>售后服务说明（此政策2016年8月1日开始实施）
        因质量原因出现机器故障的，可享受智驹 18个月的保修的售后服务。</p>
      <p>18个月内保修：自购机之日起18个月内，用户在使用的过程中，遇到非人为故障，导致机器无法正常工作，您可以联系智驹客服检测，享受免费保修服务；购机18个月以上需收取材料费及人工费（收费标准按国家物价部门要求执行）。</p>
    </div>

  </div>
</template>

<script>
export default {
  name: "售后政策"
}
</script>

<style scoped>
.size_box {
  width: 80%;
  margin: auto;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-top: 50px;
}

p {
  margin-bottom: 20px;
  text-indent: 2em;
}
</style>